<script setup>
import { ref, onMounted } from 'vue'
import axios from 'axios'
// 解决数据驾驶舱切换tab报错
//-------------------------------------------------------
const ignoreErrors = ['ResizeObserver loop completed with undelivered notifications', 'ResizeObserver loop limit exceeded']

window.addEventListener('error', (e) => {
  let errorMsg = e.message
  ignoreErrors.forEach((m) => {
    if (errorMsg.startsWith(m)) {
      console.error(errorMsg)
      if (e.error) {
        console.error(e.error.stack)
      }
      const resizeObserverErrDiv = document.getElementById('webpack-dev-server-client-overlay-div')
      const resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay')
      if (resizeObserverErr) {
        resizeObserverErr.setAttribute('style', 'display: none')
      }
      if (resizeObserverErrDiv) {
        resizeObserverErrDiv.setAttribute('style', 'display: none')
      }
    }
  })
})
//-------------------------------------------------------
const activeName = ref('first')
const areaDataList = ref([])
const schoolDataList = ref([])
onMounted(() => {
  getareaList()
  getSchoolDataList()
})
const getareaList = async () => {
  const response = await axios.get('api/baseInfo/query/areaData')
  areaDataList.value = response.data
}
const getSchoolDataList = async () => {
  const response = await axios.get('api/baseInfo/query/schoolData')
  schoolDataList.value = response.data
}
</script>
<template>
  <div class="col-span-3 p-4 space-y-2 rounded bg-slate-50" style="height: 300px">
    <div class="flex justify-between items-center">
      <div class="text-xl font-bold text-gray-600">数据驾驶舱</div>
    </div>
    <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
      <el-tab-pane label="区域数据" name="first">
        <el-table
          :data="areaDataList"
          style="width: 100%"
          height="190"
          :header-cell-style="{ 'text-align': 'center' }"
          :cell-style="{ 'text-align': 'center' }"
        >
          <el-table-column prop="province" label="省" />
          <el-table-column prop="city" label="市" />
          <el-table-column prop="area" label="区" />
          <el-table-column prop="schoolNumber" label="学校数量" />
          <el-table-column prop="studentNumber" label="总人数" />
          <el-table-column prop="gykNumber" label="公益人数" />
          <!-- <el-table-column prop="jctnNumber" label="基础体能课人数" /> -->
          <el-table-column prop="hxzxNumber" label="核心专项人数" />
          <el-table-column prop="conversionRate" label="转化率" />
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="学校数据" name="second">
        <el-table
          :data="schoolDataList"
          style="width: 100%"
          height="190"
          :header-cell-style="{ 'text-align': 'center' }"
          :cell-style="{ 'text-align': 'center' }"
        >
          <el-table-column prop="areaName" label="所在区域" />
          <el-table-column prop="schoolName" label="学校名称" />
          <el-table-column prop="studentNumber" label="总人数" />
          <el-table-column prop="gykNumber" label="公益人数" />
          <!-- <el-table-column prop="jctnNumber" label="基础体能课人数" /> -->
          <el-table-column prop="hxzxNumber" label="核心专项人数" />
          <el-table-column prop="conversionRate" label="转化率" />
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="前台数据" name="third">
        <el-table :data="customerServiceDataList" style="width: 100%" height="190">
          <el-table-column prop="areaName" label="客服姓名" />
          <el-table-column prop="schoolName" label="学校名称" />
          <el-table-column prop="schoolName" label="所辖年级" />
          <el-table-column prop="schoolName" label="班级" />
          <el-table-column prop="studentNumber" label="总人数" />
          <el-table-column prop="gykNumber" label="公益人数" />
          <el-table-column prop="hxzxNumber" label="核心专项人数" />
          <el-table-column prop="conversionRate" label="转化率" />
        </el-table>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<style scoped>
.info-list-node {
  position: relative;
  margin-left: 1rem;
}

.info-list-node::before {
  content: '';
  position: absolute;
  left: -15px;
  top: 0.5rem;
  width: 6px;
  height: 6px;
  background-color: orange;
  border-radius: 50%;
}
</style>
