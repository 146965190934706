<template>
  <el-dialog title="用户管理" width="1300" :model-value="props.visible" :before-close="handleClose">
    <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
      <el-tab-pane label="用户列表" name="first">
        <AppletUserManag />
      </el-tab-pane>
      <el-tab-pane label="滚动通知管理" name="second"> </el-tab-pane>
    </el-tabs>
    <template v-slot:footer>
      <div class="dialog-footer">
        <el-button @click="close"> 关闭 </el-button>
        <el-button type="primary" @click="imgUploadSave(file)">保存</el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script setup>
// import axios from 'axios'
import { ref } from 'vue'
import AppletUserManag from './AppletUserManag.vue'

const activeName = ref('first')
const props = defineProps({
  visible: Boolean,
})
const emits = defineEmits(['update:visible'])
const close = () => {
  emits('update:visible', false)
}
// 右上角关闭
const handleClose = () => {
  emits('update:visible', false)
}
</script>

