<script setup>
import { ref } from 'vue'
const activeName = ref('first')
</script>
<template>
  <div class="col-span-3 p-4 space-y-2 rounded bg-slate-50" style="height: 300px">
    <div class="flex justify-between items-center">
      <div class="text-xl font-bold text-gray-600">常用功能</div>
    </div>
    <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
      <el-tab-pane label="OA申请" name="first">
        <a href="#" style="color: blue; text-decoration: underline; font-size: 14px">请假申请</a><br />
        <a href="#" style="color: blue; text-decoration: underline; font-size: 14px">费用申请</a><br />
        <a href="#" style="color: blue; text-decoration: underline; font-size: 14px">报销申请</a><br />
        <a href="#" style="color: blue; text-decoration: underline; font-size: 14px">出库申请</a><br />
      </el-tab-pane>
      <el-tab-pane label="数据倒入" name="second">Config</el-tab-pane>
      <el-tab-pane label="训练影像素材" name="third">Role</el-tab-pane>
    </el-tabs>
  </div>
</template>
<style scoped>
.info-list-node {
  position: relative;
  margin-left: 1rem;
}

.info-list-node::before {
  content: '';
  position: absolute;
  left: -15px;
  top: 0.5rem;
  width: 6px;
  height: 6px;
  background-color: orange;
  border-radius: 50%;
}
</style>
