<template>
  <el-dialog title="校外专项训练" width="1200" :model-value="props.visible" :before-close="handleClose">
    <span class="ztgh">整体规划:</span><br />
    <div class="pwc">
      <span>
        缴费课: <br />初一：A2+2- B1- C1+3+9 D1+2+3+4-E13+15+17-F全套<br />初二：A2+2+2-B1-C（1+3+9 ）x2-D（1+2+3+4）x2-E13+15+17-F全套<br />
        初三：A2+2+2-B1-C（1+3+9 ）x2-D（1+2+3+4）x2-E13+15+17-F全套
      </span>
    </div>
    <span>课程体系：</span><br /><br />
    <el-tooltip effect="customized">
      <template #content
        >1.跪支侧抬腿<br />2.跪支直腿上举<br />3.跪支前绕腿<br />4.跪支后绕腿<br />5.跪支屈腿后抬<br />6.侧身屈腿开合<br />7.侧身旋转腿</template
      >
      <el-button>第一课</el-button>
    </el-tooltip>
    <el-tooltip effect="customized">
      <template #content>1.小栏架侧面高抬腿<br />2.小栏架正面高抬腿<br />3.起跑10米<br />4.起跑30米</template>
      <el-button>第二课</el-button>
    </el-tooltip>
    <el-tooltip effect="customized">
      <template #content
        >1.50米高抬腿×2组<br />2.30米小步跑×2组<br />3.50米前踢腿×2组<br />4.60米后蹬跑×2组<br />5.60米跨步跳×2组<br />6.负重仰卧起坐20次×3组<br />7.负重背起20次×3组<br />8.侧肌静立1分钟×3组</template
      >
      <el-button>第三课</el-button>
    </el-tooltip>
    <el-tooltip effect="customized">
      <template #content
        >起跑训练<br />1.蹬起跑器跑10米×4组（每个间歇3分钟)<br />2.蹬起跑器跑30米×3组（每个间歇4分钟)<br />3.蹬起跑器跑60米×2组（每个间歇6分钟)</template
      >
      <el-button>第四课</el-button>
    </el-tooltip>
    <el-tooltip effect="customized">
      <template #content>专门性训练<br />慢跑，拉伸<br />高抬腿，小步跑，前踢腿，后蹬跑，跨步跳</template>
      <el-button>第五课</el-button>
    </el-tooltip>
    <el-tooltip effect="customized">
      <template #content>专项训练<br />慢跑三圈，动态拉伸，专门性训练<br />1.起跑10米<br />2.起跑30米<br />3.起跑60米</template>
      <el-button>第六课</el-button>
    </el-tooltip>
    <el-tooltip effect="customized">
      <template #content
        >上下肢力量训练<br />1.哑铃肱二头肌6次×3组<br />2.哑铃肱三头肌6次×3组<br />3.哑铃三角肌6次×3组<br />4.哑铃向上方举6次×3组<br />5.杠铃杆一步上5次×3组<br />6.杠铃杆硬拉3次×5组<br />7.前抛球×3组<br />8.后抛球×3组</template
      >
      <el-button>第七课</el-button>
    </el-tooltip>
    <el-tooltip effect="customized">
      <template #content>1.30米行进间跑×4组（每个间歇4分钟)<br />2.60米行进间跑×3组（每个间歇5分钟)<br />3.80米行进间跑×1组</template>
      <el-button>第八课</el-button>
    </el-tooltip>
    <br /><br />
    <el-tooltip effect="customized">
      <template #content>专项训练<br />慢跑，动态拉伸，专门性<br />1.起跑10米<br />2.起跑30米<br />3.起跑60米</template>
      <el-button>第九课</el-button>
    </el-tooltip>
    <el-tooltip effect="customized">
      <template #content>臀大肌抗酸训练<br />1.正弓步走，倒弓步走，侧弓步走</template>
      <el-button>第十课</el-button>
    </el-tooltip>
    <el-tooltip effect="customized">
      <template #content
        >核心训练<br />1.立木举腿10次×3组<br />2.腹肌静立抛球15次×3组<br />3.剪刀脚双腿80次×3组<br />4.轮换至胸20次×3组<br />5.悬空屈腿90度摸脚后跟20次×3组<br />6.腹肌静立交叉腿30次×3组<br />7.压肩腿起30次×3组<br />8.压脚肩起30次×3组<br />9.两头起30次×3组<br />10.左右转体80次×3组<br />11.屈腿摸双脚30次×3组<br />12.压脚侧起20次×3组</template
      >
      <el-button>第十一课</el-button>
    </el-tooltip>
    <el-tooltip effect="customized">
      <template #content>1.向前走10个栏架×4次×2组<br />2.屈腿侧面走10个栏架×4组<br />3.直腿侧面走10个栏架×4组<br />4.钻栏10个架×4组</template>
      <el-button>第十二课</el-button>
    </el-tooltip>
    <el-tooltip effect="customized">
      <template #content
        >专项速度训练<br />1.蹬起跑器跑10米×4组（每个间歇3分钟)<br />2.蹬起跑器跑30米×3组（每个间歇4分钟)<br />3.蹬起跑器跑60米×2组（每个间歇6分钟)</template
      >
      <el-button>第十三课</el-button>
    </el-tooltip>
    <el-tooltip effect="customized">
      <template #content
        >上下肢力量训练<br />1.杠铃杆高翻3次×5组<br />2.杠铃杆抓举5次×5组<br />3.杠铃杆斜前推5次×5组<br />4.杠铃杆半蹲5次×5组<br />5.壶铃半蹲跳8次×5组<br />6.沙坑立定跳远×3组<br />7.沙坑立定两段跳×2组<br />8.沙坑三级跳×2组</template
      >
      <el-button>第十四课</el-button>
    </el-tooltip>
    <el-tooltip effect="customized">
      <template #content
        >臀大肌抗酸训练<br />1.跪支侧抬腿20次×3组<br />2.跪支直腿上举20次×3组<br />3.跪支前绕腿20次×3组<br />4.跪支后绕腿20次×3组<br />5.跪支屈腿后抬20次×3组<br />6.侧身屈腿开合30次×3组<br />7.侧身旋转腿20次×3组</template
      >
      <el-button>第十五课</el-button>
    </el-tooltip>
    <el-tooltip effect="customized">
      <template #content>专项耐力训练<br />1.120米×6组（每个间歇8～10分钟)</template>
      <el-button>第十六课</el-button>
    </el-tooltip>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="close"> 关闭 </el-button>
      </div></template
    >
  </el-dialog>
</template>
<script setup>
const props = defineProps({
  visible: Boolean,
})
const emits = defineEmits(['update:visible'])
const close = () => {
  emits('update:visible', false)
}
// 右上角关闭
const handleClose = () => {
  emits('update:visible', false)
}
</script>
<style >
.ztgh {
  font-size: 18px;
}
.pwc {
  margin-left: 90px;
}
.el-popper.is-customized {
  padding: 6px 12px;
  background: linear-gradient(90deg, rgb(159, 229, 151), rgb(204, 229, 129));
}

.el-popper.is-customized .el-popper__arrow::before {
  background: linear-gradient(45deg, #b2e68d, #bce689);
  right: 0;
}
.pc {
  margin-left: 400px;
}
</style>

