<template>
  <el-dialog title="管理制度上传" width="1000" :model-value="props.visible" :before-close="handleClose">
    <el-upload class="upload-demo" drag action="/api/training/results/import">
      <el-icon class="el-icon--upload"><upload-filled /></el-icon>
      <div class="el-upload__text">选择上传文件</div>
      <template #tip>
        <div class="el-upload__tip">文件必须为excel文件</div>
      </template>
    </el-upload>
    <template #footer>
      <div class="dialog-footer">
        <el-button type="primary" @click="close"> 关闭 </el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script setup>
const props = defineProps({
  visible: Boolean,
})
const emits = defineEmits(['update:visible'])
const close = () => {
  emits('update:visible', false)
}
// 右上角关闭
const handleClose = () => {
  emits('update:visible', false)
}
</script>
<script >
import { UploadFilled } from '@element-plus/icons-vue'
export default {
  name: 'StudentBaseInfoImport',
  components: {},
}
</script>
