<template>
  <header class="flex justify-center items-center pt-8 pb-4 space-x-4 text-gray-400">
    <div class="login">
      <a class="w-24" href="">
        <img src="https://hsczty.com/web/cztyLogo.png" alt="" />
      </a>
    </div>
    <div class="welcome">
      <span style="font-size: 20px; color: black">超级管理员</span>
      <span style="font-size: 15px">欢迎您～</span>
    </div>
    <span style="font-size: 13px; color: black"
      ><p>{{ currentDateTime }}</p></span
    >
    <ul class="flex mt-10">
      <li class="px-4 text-sm border-r border-gray-200">登录</li>
    </ul>
  </header>
</template>

<script>
import { ref, computed } from 'vue'

export default {
  name: 'HomeHeader',
  setup() {
    const currentDateTime = ref(new Date())

    const updateCurrentDateTime = () => {
      currentDateTime.value = new Date()
    }

    setInterval(updateCurrentDateTime, 1000) // 每秒更新一次当前时间

    return {
      currentDateTime: computed(() => {
        const datetime = currentDateTime.value
        const year = datetime.getFullYear()
        const month = String(datetime.getMonth() + 1).padStart(2, '0')
        const date = String(datetime.getDate()).padStart(2, '0')
        const hours = String(datetime.getHours()).padStart(2, '0')
        const minutes = String(datetime.getMinutes()).padStart(2, '0')
        const seconds = String(datetime.getSeconds()).padStart(2, '0')
        const weekdays = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
        const dayOfWeek = weekdays[datetime.getDay()]

        return `${year}-${month}-${date} ${hours}:${minutes}:${seconds} ${dayOfWeek}`
      }),
    }
  },
}
</script>

<style scoped>
.login {
  width: 220px;
  margin-right: 800px;
}
</style>
