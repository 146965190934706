<template>
  <el-dialog title="打卡用户列表" width="1000" :model-value="props.visible" :before-close="handleClose">
    <el-table :data="infoList" style="width: 100%">
      <el-table-column label="姓名" prop="parentName" />
      <el-table-column label="手机号" prop="phone" />
      <el-table-column align="right"> </el-table-column>
    </el-table>
    <template #footer>
      <div class="dialog-footer">
        <el-button type="primary" @click="close"> 关闭 </el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script setup>
import axios from 'axios'
import { ref } from 'vue'
import { onMounted } from 'vue'
const props = defineProps({
  visible: Boolean,
})
const infoList = ref([])
const emits = defineEmits(['update:visible'])

onMounted(() => {
  getInfoList()
})
// 获取首页信息
const getInfoList = async () => {
  const response = await axios.get('/api/user/query/SigInList')
  infoList.value = response.data
}
const close = () => {
  emits('update:visible', false)
}
// 右上角关闭
const handleClose = () => {
  emits('update:visible', false)
}
</script>
<script >
export default {
  name: 'StudentBaseInfoImport',
  components: {},
}
</script>
