<template>
  <el-dialog title="校内公益训练" width="1200" :model-value="props.visible" :before-close="handleClose">
    <span class="ztgh">整体规划:</span><br />
    <div class="pwc">
      <span
        >公益课: <br />初一：a2- b3- c9-d1- e2- f6 <br />初二： a2- b5-c5- d2- e4- f6 <br />
        初三：a2+2- b4-c5+5-d1+2- e1-f5+6</span
      >
    </div>
    <span>课程体系：</span><br /><br />
    <el-tooltip effect="customized">
      <template #content>基础有氧（时间跑）<br />游戏15-20分钟<br />腰腹训练</template>
      <el-button>课程一</el-button>
    </el-tooltip>
    <el-tooltip effect="customized">
      <template #content>基础有氧 3-4km<br />基础力量训练（教学阶段）<br />游戏15-20分钟</template>
      <el-button>课程二</el-button>
    </el-tooltip>
    <el-tooltip effect="customized">
      <template #content>中长跑专项速度训练150m—300m<br />间歇跑<br />有氧训练</template>
      <el-button>课程三</el-button>
    </el-tooltip>
    <el-tooltip effect="customized">
      <template #content>有氧时间跑<br />腰腹肌静态 肌肉耐力训练<br />游戏15-20分钟<br />有氧慢跑</template>
      <el-button>课程四</el-button>
    </el-tooltip>
    <el-tooltip effect="customized">
      <template #content>比赛检查跑<br />灵活性训练（兼备游戏训练，接力跑，检查跑，追逐跑）</template>
      <el-button>课程五</el-button>
    </el-tooltip>
    <el-tooltip effect="customized">
      <template #content>有氧恢复（慢时间跑）<br />基础力量训练<br />灵活性调动训练3-5组</template>
      <el-button>课程六</el-button>
    </el-tooltip>
    <el-tooltip effect="customized">
      <template #content>有氧耐力准备活动<br />专项耐力训练（乳酸阈值跑）</template>
      <el-button>课程七</el-button>
    </el-tooltip>
    <el-tooltip effect="customized">
      <template #content>有氧恢复<br />灵活性训练<br />游戏灵活性：栏架和比赛</template>
      <el-button>课程八</el-button>
    </el-tooltip>
    <br /><br />
    <el-tooltip effect="customized">
      <template #content>基础有氧 3-4km<br />基础力量训练（教学阶段）<br />游戏15-20分钟</template>
      <el-button>课程九</el-button>
    </el-tooltip>
    <el-tooltip effect="customized">
      <template #content>专项检查跑<br />基础力量训练<br />灵活性调动（游戏追逐比赛）</template>
      <el-button>课程十</el-button>
    </el-tooltip>
    <el-tooltip effect="customized">
      <template #content>有氧耐力比赛日（最大有氧耐力测试）<br />放松拉伸</template>
      <el-button>课程十一</el-button>
    </el-tooltip>
    <el-tooltip effect="customized">
      <template #content>基础有氧 3-4km<br />基础力量训练（教学阶段）<br />游戏15-20分钟</template>
      <el-button>课程十二</el-button>
    </el-tooltip>
    <el-tooltip effect="customized">
      <template #content>专项测试</template>
      <el-button>课程十三</el-button>
    </el-tooltip>
    <el-tooltip effect="customized">
      <template #content>有氧恢复<br />灵活性训练<br />游戏灵活性：栏架和比赛</template>
      <el-button>课程十四</el-button>
    </el-tooltip>
    <el-tooltip effect="customized">
      <template #content>专项检查跑<br />基础力量训练<br />灵活性调动（游戏追逐比赛）</template>
      <el-button>课程十五</el-button>
    </el-tooltip>
    <el-tooltip effect="customized">
      <template #content>有氧耐力比赛日（最大有氧耐力测试）</template>
      <el-button>课程十六</el-button>
    </el-tooltip>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="close"> 关闭 </el-button>
      </div></template
    >
  </el-dialog>
</template>
<script setup>
const props = defineProps({
  visible: Boolean,
})
const emits = defineEmits(['update:visible'])
const close = () => {
  emits('update:visible', false)
}
// 右上角关闭
const handleClose = () => {
  emits('update:visible', false)
}
</script>
<style >
.ztgh {
  font-size: 18px;
}
.pwc {
  margin-left: 90px;
}
.el-popper.is-customized {
  padding: 6px 12px;
  background: linear-gradient(90deg, rgb(159, 229, 151), rgb(204, 229, 129));
}

.el-popper.is-customized .el-popper__arrow::before {
  background: linear-gradient(45deg, #b2e68d, #bce689);
  right: 0;
}
</style>

