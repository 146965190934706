<script lang="ts">
</script>
<template>
  <div class="main_div">
    <div class="d">
      <span style="color: #434a58">生日提醒:</span>
      <br />
      <span style="color: #434a58">今日：</span>
      <span style="color: #434a58">王予涵,张梓萱；<br />李佳洋,冷守业</span>

      <br /><br /><br />
      <span style="color: #434a58">明日：</span>
      <span style="color: #434a58">吴司怡；魏皓冉；<br />刘冲峰</span>
    </div>
  </div>
</template>
<style scoped>
.main_div {
  height: 100%;
  background-color: #f7f9fb;
  /* 设置圆角 */
  border-radius: 5px;
}
.d {
  margin-left: 10px;
  padding-top: 10px;
}
</style>

