<template>
  <el-dialog title="学生基础信息" width="1300" :model-value="props.visible" :before-close="handleClose">
    <!-- <el-upload class="upload-demo" drag action="/api/training/results/export">
      <el-icon><Download /></el-icon>
      <div class="el-upload__text">选择导出路径</div>
    </el-upload> -->
    <el-select v-model="value" placeholder="选择学校" style="width: 240px">
      <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
    </el-select>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="date" label="Date" width="180" />
      <el-table-column prop="name" label="Name" width="180" />
      <el-table-column prop="address" label="Address" />
    </el-table>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="close"> 关闭 </el-button>
        <el-button type="primary" @click="excelClink"> 导出 </el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script setup>
import axios from 'axios'
const props = defineProps({
  visible: Boolean,
})
const emits = defineEmits(['update:visible'])
// 关闭
const close = () => {
  emits('update:visible', false)
}
// 右上角关闭
const handleClose = () => {
  emits('update:visible', false)
}

const excelClink = async () => {
  try {
    // 发送请求，获取文件流
    const response = await axios.get('/api/baseInfo/export/notUploaded', {
      responseType: 'blob', // 告诉axios我们期望一个二进制大型对象(Blob)
    })
    // 创建一个临时URL指向我们的文件对象
    const url = window.URL.createObjectURL(new Blob([response.data]))
    // 创建<a>元素，并模拟点击实现下载
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', '学生基础信息.xlsx') // 指定下载文件名
    document.body.appendChild(link)
    link.click() // 模拟点击

    // 清理DOM和释放临时创建的URL
    document.body.removeChild(link)
    window.URL.revokeObjectURL(url)
  } catch (error) {
    console.error('下载文件出错:', error)
  }
}
</script>
<script >
// import { Download } from '@element-plus/icons-vue'
export default {
  name: 'StudentBaseInfoImport',
  components: {},
}
</script>
