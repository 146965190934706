<script>
// import HomeFooter from './components/Footer.vue'
import HomeHeader from './components/Header.vue'
import HomeNav from './components/Title.vue'
import HomeSlide from './components/Slide.vue'
import Schedule from './components/ComponentSchedule.vue'
import MessageReminder from './components/MessageReminder.vue'
import DataCockpit from './components/DataCockpit.vue'
import PublicitySearch from './components/PublicitySearch.vue'
import MonthlyAssessment from './components/MonthlyAssessment.vue'
import CompanyIntroduce from './components/CompanyIntroduce.vue'
import CompanyBrandPromotion from './components/CompanyBrandPromotion.vue'
import CommonFunctions from './components/CommonFunctions.vue'
import BirthdayReminder from './components/BirthdayReminder.vue'
import LoginNumber from './components/LoginNumber.vue'
// import ComponentOther from './components/ComponentOther.vue'
import StudentBaseInfoImport from './customerService/StudentBaseInfoImport.vue'
export default {
  name: 'HomePage',
  components: {
    HomeHeader,
    HomeNav,
    HomeSlide,
    // HomeFooter,
    Schedule,
    MessageReminder,
    DataCockpit,
    PublicitySearch,
    MonthlyAssessment,
    CompanyIntroduce,
    CompanyBrandPromotion,
    CommonFunctions,
    BirthdayReminder,
    StudentBaseInfoImport,
    LoginNumber,
    // ComponentOther,
  },
}
</script>
<template>
  <StudentBaseInfoImport />
  <HomeHeader />
  <HomeNav />
  <img src="https://hsczty.com/web/title.png" style="width: 2600px; height: 200px; padding-left: 10%; padding-right: 10%; padding-top: 1%" />
  &nbsp;
  <main style="padding-left: 10%; padding-right: 10%">
    <div class="grid-content ep-bg-purple" />
    <el-row :gutter="20">
      <el-col :span="21">
        <el-row :gutter="20">
          <el-col :span="8"><HomeSlide /></el-col>
          <el-col :span="8" class="two_div"><Schedule /></el-col>
          <el-col :span="8" class="three_div"><MessageReminder /></el-col>
        </el-row>
      </el-col>
      <el-col :span="3" :gutter="24"><BirthdayReminder /></el-col>
    </el-row>
    &nbsp;
    <el-row :gutter="20">
      <el-col :span="21">
        <el-row :gutter="20">
          <el-col :span="8"><MonthlyAssessment /></el-col>
          <el-col :span="16"><DataCockpit /></el-col>
        </el-row>
      </el-col>
      <el-col :span="3" :gutter="24"><LoginNumber /></el-col>
    </el-row>
    &nbsp;
    <el-row :gutter="20">
      <el-col :span="21">
        <el-row :gutter="20">
          <el-col :span="8" class="three_div"><PublicitySearch /></el-col>

          <el-col :span="8"><CompanyIntroduce /></el-col>
          <el-col :span="8"><CompanyBrandPromotion /></el-col>
        </el-row>
      </el-col>
      <!-- <el-col :span="8" class="three_div"><CommonFunctions /></el-col> -->
      <el-col :span="3" class="three_div"><CommonFunctions /></el-col>

      <!-- <el-col :span="3" :gutter="24"><ComponentOther /></el-col> -->
    </el-row>
  </main>
  <br />
  <!-- <div class="bg-[#403e3f]">
    <HomeFooter />
  </div> -->
</template>

<style>
</style>
