<template>
  <el-dialog title="训练工具包下载" width="1500" :model-value="props.visible" :before-close="handleClose">
    <el-input v-model="a" style="width: 130px" autosize type="textarea" placeholder="A" />
    <el-input v-model="b" style="width: 130px" autosize type="textarea" placeholder="B" />
    <el-input v-model="c" style="width: 130px" autosize type="textarea" placeholder="C" />
    <el-input v-model="d" style="width: 130px" autosize type="textarea" placeholder="D" />
    <el-input v-model="e" style="width: 130px" autosize type="textarea" placeholder="E" />
    <el-input v-model="f" style="width: 130px" autosize type="textarea" placeholder="F" />
    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="a" label="A" width="180" />
      <el-table-column prop="aname" label="A名称" width="180" />
      <el-table-column prop="b" label="B" width="180" />
      <el-table-column prop="bname" label="B名称" width="180" />
      <el-table-column prop="c" label="C" width="180" />
      <el-table-column prop="cname" label="C名称" width="180" />
      <el-table-column prop="d" label="D" width="180" />
      <el-table-column prop="dname" label="D名称" width="180" />
      <el-table-column prop="e" label="E" width="180" />
      <el-table-column prop="ename" label="E名称" width="180" />
      <el-table-column prop="f" label="F" width="180" />
      <el-table-column prop="fname" label="F名称" width="180" />
    </el-table>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="close"> 关闭 </el-button>
        <el-button type="primary" @click="excelClink"> 导出 </el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script setup>
import axios from 'axios'
import { onMounted, ref, reactive } from 'vue'
const props = defineProps({
  visible: Boolean,
})
const emits = defineEmits(['update:visible'])
let tableData = reactive({
  a: '',
  aName: '',
  b: '',
  bName: '',
  c: '',
  cName: '',
  d: '',
  dName: '',
  e: '',
  eName: '',
  f: '',
  fName: '',
})

onMounted(() => {
  getList()
})

const a = ref('')
const b = ref('')
const c = ref('')
const d = ref('')
const e = ref('')
const f = ref('')
// 关闭
const close = () => {
  emits('update:visible', false)
}
// 右上角关闭
const handleClose = () => {
  emits('update:visible', false)
}
const excelClink = async () => {
  const dataParam = {
    a: a.value,
    b: b.value,
    c: c.value,
    d: d.value,
    e: e.value,
    f: f.value,
  }
  try {
    // 发送请求，获取文件流
    const response = await axios.get('/api/coach/trainingPlan/Export', {
      responseType: 'blob', // 告诉axios我们期望一个二进制大型对象(Blob)
      params: dataParam,
    })
    // 创建一个临时URL指向我们的文件对象
    const url = window.URL.createObjectURL(new Blob([response.data]))
    // 创建<a>元素，并模拟点击实现下载
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', '训练工具包.xlsx') // 指定下载文件名
    document.body.appendChild(link)
    link.click() // 模拟点击

    // 清理DOM和释放临时创建的URL
    document.body.removeChild(link)
    window.URL.revokeObjectURL(url)
  } catch (error) {
    console.error('下载文件出错:', error)
  }
}
const getList = () => {
  axios
    .get('/api/coach/queryTrainingPlan/list')
    .then((response) => {
      tableData = response.data
    })
    .catch((error) => {
      console.error('Error fetching data:', error)
    })
}
</script>
<script >
export default {
  name: 'TrainingPlanExport',
  components: {},
}
</script>
