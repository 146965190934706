<template>
  <el-dialog title="轮播图管理" style="width: 600px; height: 150px" :model-value="props.visible" :before-close="handleClose">
    <form @submit.prevent="submitForm">
      <input type="file" @change="handleFileChange" />
      <button type="submit">上传</button>
    </form>
    <template #footer>
      <div class="dialog-footer">
        <el-button type="primary" @click="close"> 关闭 </el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script setup>
import { onMounted } from 'vue'
import { ref } from 'vue'
import axios from 'axios'

const props = defineProps({
  visible: Boolean,
  userId: String,
})
const emits = defineEmits(['update:visible'])
onMounted(() => {})
const close = () => {
  emits('update:visible', false)
}
// 右上角关闭
const handleClose = () => {
  emits('update:visible', false)
}
// 使用 ref 创建一个响应式的 selectedFile 变量
const selectedFile = ref(null)

// 定义 handleFileChange 函数
const handleFileChange = (event) => {
  selectedFile.value = event.target.files[0]
}

// 定义 submitForm 函数，并使用 async/await 处理异步操作
const submitForm = async () => {
  const formData = new FormData()
  formData.append('file', selectedFile.value)

  try {
    const response = await axios.post('/api/image/uploadApplet/swiper', formData, {
      params: { userId: props.userId.id },
    })
    console.log(response.data)
  } catch (error) {
    console.error(error)
  }
}
</script>
