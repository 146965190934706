<template>
  <el-dialog title="信息2345613178管理" width="1000" :model-value="props.visible" :before-close="handleClose">
    <el-button color="#1E90FF" plain @click="open">添加滚动消息</el-button>
    <el-button color="#1E90FF" plain @click="addNewsInfo">添加新闻消息</el-button>
    <el-table :data="infoList" style="width: 100%">
      <el-table-column label="信息" prop="notice" />
      <el-table-column label="新闻" prop="news" />
      <el-table-column label="类型" prop="type">
        <template v-slot="scope">
          <span v-if="scope.row.type === 0">信息</span>
          <span v-else-if="scope.row.type === 1">新闻</span>
        </template>
      </el-table-column>
      <el-table-column align="right">
        <template #default="scope">
          <el-button size="small" @click="handleEdit(scope.$index, scope.row)">修改</el-button>
          <el-button size="small" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <template #footer>
      <div class="dialog-footer">
        <el-button type="primary" @click="close"> 关闭 </el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script setup>
import axios from 'axios'
import { ref } from 'vue'
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'
const props = defineProps({
  visible: Boolean,
})
const infoList = ref([])
const emits = defineEmits(['update:visible'])

onMounted(() => {
  getInfoList()
})
// 获取首页信息
const getInfoList = async () => {
  const response = await axios.get('/api/applet/queryRollingInfoList')
  infoList.value = response.data
}
// const rollingInfo = ref('')
const close = () => {
  emits('update:visible', false)
}
// 右上角关闭
const handleClose = () => {
  emits('update:visible', false)
}
import { ElMessage, ElMessageBox } from 'element-plus'
// 添加滚动信息
const open = () => {
  ElMessageBox.prompt('请输入滚动信息', '添加滚动信息', {
    confirmButtonText: '提交',
    cancelButtonText: '取消',
    inputErrorMessage: 'Invalid Email',
  })
    .then(({ value }) => {
      const response = axios.post('/api/applet/insertRollingInfo', null, {
        params: {
          rollingInfo: value,
        },
      })
      ElMessage({
        type: 'success',
        message: `添加成功`,
      })
      // 刷新页面
      const router = useRouter()
      router.go(0) // 强制刷新当前页面
    })
    .catch(() => {})
}
</script>
<script >
export default {
  name: 'StudentBaseInfoImport',
  components: {},
}
</script>
