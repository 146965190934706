<script setup>
import { ref } from 'vue'
const activeName = ref('first')
</script>
<template>
  <div class="col-span-3 p-4 space-y-2 rounded bg-slate-50" style="height: 300px">
    <div class="flex justify-between items-center">
      <div class="text-xl font-bold text-gray-600">月度预核</div>
    </div>
    <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
      <el-tab-pane label="考核预警" name="first">
        <a href="#" style="color: blue; text-decoration: underline; font-size: 14px">2024年3月第一周前台业务人员考核预警.xlsx</a><br />
        <a href="#" style="color: blue; text-decoration: underline; font-size: 14px">2024年3月第二周前台业务人员考核预警.xlsx</a><br />
        <a href="#" style="color: blue; text-decoration: underline; font-size: 14px">2024年3月第三周前台业务人员考核预警.xlsx</a><br />
        <a href="#" style="color: blue; text-decoration: underline; font-size: 14px">2024年3月第四周前台业务人员考核预警.xlsx</a><br />
        <a href="#" style="color: blue; text-decoration: underline; font-size: 14px">2024年4月第一周前台业务人员考核预警.xlsx</a><br />
        <a href="#" style="color: blue; text-decoration: underline; font-size: 14px">2024年4月第二周前台业务人员考核预警.xlsx</a><br />
        <a href="#" style="color: blue; text-decoration: underline; font-size: 14px">2024年4月第三周前台业务人员考核预警.xlsx</a><br />
        <a href="#" style="color: blue; text-decoration: underline; font-size: 14px">2024年4月第四周前台业务人员考核预警.xlsx</a><br />
        <a href="#" style="color: blue; text-decoration: underline; font-size: 14px">2024年5月第一周前台业务人员考核预警.xlsx</a><br />
        <a href="#" style="color: blue; text-decoration: underline; font-size: 14px">2024年5月第二周前台业务人员考核预警.xlsx</a><br />
        <a href="#" style="color: blue; text-decoration: underline; font-size: 14px">2024年5月第三周前台业务人员考核预警.xlsx</a><br />
        <a href="#" style="color: blue; text-decoration: underline; font-size: 14px">2024年5月第四周前台业务人员考核预警.xlsx</a><br />
      </el-tab-pane>
      <el-tab-pane label="方案预警" name="second">Config</el-tab-pane>
      <el-tab-pane label="薪资预结" name="third">Role</el-tab-pane>
    </el-tabs>
  </div>
</template>
<style scoped>
.info-list-node {
  position: relative;
  margin-left: 1rem;
}

.info-list-node::before {
  content: '';
  position: absolute;
  left: -15px;
  top: 0.5rem;
  width: 6px;
  height: 6px;
  background-color: orange;
  border-radius: 50%;
}
</style>
