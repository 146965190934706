<template>
  <div class="block text-center">
    <el-carousel height="300px">
      <el-carousel-item v-for="(slide, index) in slides" :key="index">
        <a :href="slide.url" target="_blank">
          <img class="rounded" style="height: 100%; width: 100%" :src="slide.image" :alt="slide.caption" />
        </a>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  name: 'HomeSlide',
  data() {
    return {
      slides: [
        { image: 'https://hsczty.com/web/1.jpg' },
        { image: 'https://hsczty.com/web/2.jpg' },
        { image: 'https://hsczty.com/web/3.jpg' },
        { image: 'https://hsczty.com/web/4.jpg' },
      ],
      currentIndex: 0,
      timer: null,
    }
  },
  mounted() {
    this.startAutoSlide()
  },
  methods: {
    prevSlide() {
      this.currentIndex = (this.currentIndex + this.slides.length - 1) % this.slides.length
      this.resetAutoSlide()
    },
    nextSlide() {
      this.currentIndex = (this.currentIndex + 1) % this.slides.length
      this.resetAutoSlide()
    },
    startAutoSlide() {
      this.timer = setInterval(() => {
        this.currentIndex = (this.currentIndex + 1) % this.slides.length
      }, 6000)
    },
    // 如果主动切换图片则重置自动切换时间
    resetAutoSlide() {
      clearInterval(this.timer)
      this.startAutoSlide()
    },
  },
}
</script>

<style scoped>
.demonstration {
  color: var(--el-text-color-secondary);
}

.el-carousel__item h3 {
  color: #475669;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
  text-align: center;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>
