<template>
  <el-table :data="tableData" style="width: 100%" :row-key="rowKey">
    <el-table-column type="selection" width="55"></el-table-column>
    <el-table-column prop="province" label="省" width="80" align="center" />
    <el-table-column prop="city" label="市" width="80" align="center" />
    <el-table-column prop="area" label="区" width="80" align="center" />
    <el-table-column prop="school" label="学校" width="150" align="center" />
    <el-table-column prop="classroom" label="班级" width="100" align="center" />
    <el-table-column prop="studentName" label="学生姓名" width="100" align="center" />
    <el-table-column prop="sex" label="性别" width="80" align="center" />
    <el-table-column prop="age" label="年龄" width="80" align="center" />
    <el-table-column prop="phone" label="手机/用户名" width="120" align="center" />
    <el-table-column prop="password" label="密码" width="80" align="center" />
    <el-table-column prop="isQualityTest" label="是否体测" width="100" align="center" />
    <el-table-column prop="isMedicalHistory" label="是否有病史" width="100" align="center" />
    <el-table-column prop="isInsure" label="是否买保险" width="100" align="center" />
    <el-table-column prop="intention" label="训练意向" width="100" align="center" />
    <el-table-column prop="signIn" label="是否打卡" width="100" align="center" />
    <el-table-column fixed="right" label="操作" min-width="120" align="center">
      <template #default="{ row }">
        <el-button link type="primary" size="small" @click="getSelectedRows(row)"> 上传图片 </el-button>
      </template>
    </el-table-column>
  </el-table>
  <AppletImgManage v-model:visible="appletImgManageShow" :userId="userId" />
</template>

<script setup>
import { onMounted, ref } from 'vue'
import axios from 'axios'
import AppletImgManage from '../components/AppletImgManage.vue'

const appletImgManageShow = ref(false)

let tableData = ref([
  {
    id: '',
    phone: '',
    password: '',
    userName: '',
    province: '',
    city: '',
    area: '',
    school: '',
    grade: '',
    classroom: '',
    parentName: '',
    studentName: '',
    sex: '',
    age: 0,
    isQualityTest: 0,
    isMedicalHistory: 0,
    isInsure: 0,
    intention: 0,
    signIn: 0,
    createDate: '',
  },
])
onMounted(() => {
  getUserList()
})
const getUserList = async () => {
  const response = await axios.get('/api/user/queryUserList')
  tableData.value = structuredClone(response.data)
}
let userId = ref('')
// 获取选中的行数据
const getSelectedRows = (row) => {
  console.log('获取到的选中行数据:', row)
  userId.value = row
  appletImgManageShow.value = true
}
</script>
<script>
export default {
  components: {
    AppletImgManage,
  },
}
</script>

