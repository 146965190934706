<template>
  <div class="col-span-3 p-4 space-y-2 rounded bg-slate-50" style="height: 300px">
    <div class="flex justify-between items-center">
      <div class="text-xl font-bold text-gray-600">登录次数：</div>
    </div>
    <el-tabs v-model="activeName" class="demo-tabs">
      <el-tab-pane label="本月登陆" name="first">
        <div class="main_div">
          <div class="d">
            <span style="color: #434a58">登录次数：</span>
            <br/>
            <span style="color: #434a58">付佳明：登录2次</span><br/>
            <span style="color: #434a58">于晋：登录8次</span><br/>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="上月登陆" name="second">Config</el-tab-pane>
    </el-tabs>
  </div>
</template>

<script setup >
import {ref} from 'vue'

const activeName = ref('first')
</script>

<style scoped>
.main_div {
  height: 100%;
  background-color: #f7f9fb;
  /* 设置圆角 */
  border-radius: 5px;
}

.d {
  margin-left: 10px;
  padding-top: 10px;
}
</style>

